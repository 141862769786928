import * as React from "react"
import mainStore from "../stores/mainStore";
import "../styles.css";
import { observer } from "mobx-react-lite";
import heroImage from "../images/autocompress-hero-image-3-min.png";
import Radium from "radium";
import Button from '@atlaskit/button';
import { BitbucketIcon } from '@atlaskit/logo';


const HeroSection = () => {

    const isBrowser = () => typeof window !== "undefined";

    const launchInstallationUrl = () => {
      isBrowser() && window.open(mainStore.installationUrl,"_self");
    }

  const rearrageHeroSectionOnMobile = {
    "@media (max-width: 700px)": {
        flexDirection: "column"
    }
  };

  const showOnMobile = {
    "@media (max-width: 700px)": {
        visibility: "visible"
    }
  };
  const hideOnMobile = {
    "@media (max-width: 700px)": {
        visibility: "hidden"
    }
  };

  const titleOnMobile = {
    "@media (max-width: 700px)": {
        textAlign: "center",
        fontSize: 30
    }
  };

  const subtitleOnMobile = {
    "@media (max-width: 700px)": {
        textAlign: "center",
        fontSize: 16
    }
  };

  const buttonOnMobile = {
    "@media (max-width: 700px)": {
        alignItems: "center", justifyContent: "center"
    }
  };

  return (
    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", ...rearrageHeroSectionOnMobile }}>
        <div style={{ 
        display: "flex",
        flexDirection: "column",
        flex: 5,
        }}>
        <span style={{ color: "#253858", fontWeight: "bold", fontSize: 52, ...titleOnMobile }}>Image pushed,</span>
        <span style={{ color: "#253858", fontSize: 52, ...titleOnMobile }}>image compressed.<br></br></span>
        <div style={{height: 10}} />
            <span style={{ color: "#253858", fontSize: 24, fontWeight: "normal", ...subtitleOnMobile }}>
            <br/>Optimize your static assets while you sleep. <br/> Set today and forget forever:
        </span>
        <div style={{height: 40}} />
            <div style={{ width: "100%", display: "flex", ...buttonOnMobile }}>
                <Button appearance="primary" iconBefore={<BitbucketIcon appearance="inverse" label="" size="small" />} onClick={launchInstallationUrl}
                ><div style ={{ maxWidth: 200 }}>Install on Bitbucket</div></Button>  
            </div>
        </div>
        <div style={{  width: 10, visibility: "visible", ...hideOnMobile }}></div>
        <div style={{  height: 30, visibility: "hidden", ...showOnMobile}}></div>
        <div style={{ 
        display: "flex",
        flex: 8,
        }}>
            <img src={heroImage} style={{ width: "98%"}}></img>
        </div>
    </div>
  )
}

export default observer(Radium(HeroSection));
