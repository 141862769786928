import * as React from "react"
import { useEffect, useState } from "react";
import axios from 'axios';
import {
  Content,
  PageLayout,
  TopNavigation,
} from '@atlaskit/page-layout';
import NavBar from "../components/navbar";
import "../styles.css";
import catImage1 from "../images/cat-1.svg";
import catImage2 from "../images/cat-2.svg";
import catImage3 from "../images/cat-3.svg";
import catImage4 from "../images/cat-4.svg";
import catImage5 from "../images/cat-5.svg";
import catImage6 from "../images/cat-6.svg";
import catImage7 from "../images/cat-7.svg";
import catImage8 from "../images/cat-8.svg";
import Button from '@atlaskit/button';
import { BitbucketIcon } from '@atlaskit/logo';
import mainStore from "../stores/mainStore";
import { observer } from "mobx-react-lite";
import authService from "../services/authService";
import { Card } from "@material-ui/core";
import BlurIcon from "@atlaskit/icon/glyph/media-services/blur";
import MediaServicesZipIcon from '@atlaskit/icon/glyph/media-services/zip';
import MediaServicesImageIcon from '@atlaskit/icon/glyph/media-services/image';
import GraphBarIcon from '@atlaskit/icon/glyph/graph-bar';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import LockFilledIcon from '@atlaskit/icon/glyph/lock-filled';
import BitbucketBranchesIcon from '@atlaskit/icon/glyph/bitbucket/branches';
import { navigate } from "gatsby";
import BottomBar from "../components/bottomBar";
import Radium from 'radium';
import HeroSection from "../components/heroSection";



const Landing = () => {

  const isBrowser = () => typeof window !== "undefined";

  const launchInstallationUrl = () => {
    isBrowser() && window.open(
      mainStore.installationUrl,
      "_self");
  }
 
  useEffect(() => {
    const initialize = async () => {
      if (await authService.getAccessTokenFromRefresh()) {
        await authService.getUserFromBitbucket();
      }
    }
    initialize();    
  }, []);

  const howToSteps:any[] = [
    {
      title: "Change",
      description: "Add or modify images in your local repo. As you'd normally do.",
      img: catImage1
    },
    {
      title: "Push",
      description: "Keep on working and push your changes. As you'd normally do.",
      img: catImage2
    },
    {
      title: "Pull Request",
      description: "Autocompress optimizes the images and creates a PR for you.",
      img: catImage3
    },
    {
      title: "Merge",
      description: "Merge the PR at your convenience. Your repo is compressed!",
      img: catImage4
    },
  ];

  const benefits:any[] = [
    {
      title: "Save developer hours",
      description: "Can you think of something more productive than manually compressing images? I can.",
      img: catImage5
    },
    {
      title: "Avoid mistakes",
      description: "The new guy used a 2GB PNG and >4k visitors clicked away before the page loaded. Oops.",
      img: catImage7
    },
    {
      title: "Set and forget",
      description: "0 coding. 0 maintaining. Literally just 6 clicks. Set it once and forget for years.",
      img: catImage6
    },
    {
      title: "Speed up CI/CD pipelines",
      description: "No more numbly waiting for that deploy script to finish. Slimmer repos means faster pipes!",
      img: catImage8
    },
  ];

  const features:any[] = [
    {
      title: "Lossless & lossy",
      description: "You can configure Autocompress to prioritize image quality or image size.",
      icon: <BlurIcon label="" size="large" primaryColor="#4077D3"/>
    },
    {
      title: "Several algorithms",
      description: "Iterates over many compression libraries and selects the best results.",
      icon: <MediaServicesZipIcon label="" size="large" primaryColor="#4077D3"/>
    },
    {
      title: "All main formats supported",
      description: "Works with JPG, PNG, GIF, SVG and WEBP.",
      icon: <MediaServicesImageIcon label="" size="large" primaryColor="#4077D3"/>
    },
    {
      title: "Compression stats",
      description: "Each Autocompress PR displays how much each image was compressed and the total.",
      icon: <GraphBarIcon label="" size="large" primaryColor="#4077D3"/>
    },
    {
      title: "On-the-go & on-demand",
      description: "You can compress images as they are added or all of them in one go.",
      icon: <CheckCircleIcon label="" size="large" primaryColor="#4077D3"/>
    },
    {
      title: "Atlassian security",
      description: "Autocompress has been verified by Atlassian and gone through extensive security reviews.",
      icon: <LockFilledIcon label="" size="large" primaryColor="#4077D3"/>
    },
    {
      title: "Configurable branch",
      description: "Choose which branch Autocompress should be listening to and creating Pull Requests for.",
      icon: <BitbucketBranchesIcon label="" size="large" primaryColor="#4077D3"/>
    },
  ];

  const rearrageHeroSectionOnMobile = {
    "@media (max-width: 450px)": {
        flexDirection: "column"
    }
  };

  return (
    <PageLayout>
        <TopNavigation
            testId="topNavigation"
            id="product-navigation"
            skipLinkTitle="Product Navigation"
            height={60}
            isFixed={false}
        >
            <NavBar/>
        </TopNavigation>
        <Content testId="content">
          <div style={{ position: "relative", display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
          <div style={{
            position: "relative",
            display: "flex",
            margin: "auto",
            flexDirection: "column",
            maxWidth: 1200,
            paddingTop: 10,
            flexWrap: "wrap"
            }}>
            <Radium.StyleRoot>
              <HeroSection/>
            </Radium.StyleRoot>
            <div style={{ height: 100 }}></div>
            <span style={{ textAlign: "center", color: "#253858", fontWeight: "bold", fontSize: 40 }}>How does it work?<br></br></span>
            <div style={{ height: 10 }}></div>
            <span style={{ textAlign: "center", color: "#0052CC", fontWeight: "normal", fontSize: 24 }}>Just use Git as usual.<br></br></span>
            <div style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: 'wrap',
                  alignItems: "start",
                  justifyContent: "center"
                  }}>
                    {howToSteps.map((step) => {
                        return <Card elevation={0} style={{ maxWidth: 225, margin: 10, alignItems: "center"}}>
                          <img src={step.img} style={{ width: "100%"}}></img>
                          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start" }}>
                            <span style={{ color: "#253858", fontWeight: "normal", fontSize: 36, textAlign: "center" }}>{step.title}<br/></span>
                            <div style={{ height: 10 }}></div>
                            <span style={{ color: "#253858", fontSize: 16, fontWeight: "normal", textAlign: "center" }}>
                              {step.description}<br/>
                            </span>
                            {/* <span style={{ color: "#253858", fontSize: 16, fontWeight: "normal", textAlign: "center" }}>
                              {`Unlimited users`}<br/>
                            </span>
                            <span style={{ color: "#253858", margin: 20, fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
                              Thingy
                            </span> */}
                          </div>
                        </Card>
                  })}
                </div>
            <div style={{ height: 150 }}></div>
            <span style={{ textAlign: "center", color: "#253858", fontWeight: "bold", fontSize: 40 }}>Why use Autocompress?<br></br></span>
            <div style={{ height: 20 }}></div>
            <span style={{ color: "#4A6798", fontSize: 18, fontWeight: "normal", textAlign: "center" }}>
                Autocompress is the simplest, most effective solution for image compression. Avoid manually compressing images, maintaining compression pipelines or implementing unnecessary CDNs.
                You get the benefits of lighter images with no effort.
              </span>
            <div style={{ height: 40 }}></div>
            <div style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: 'wrap',
                  alignItems: "start",
                  justifyContent: "center",
                  backgroundColor: "#E3EFFF",
                  borderRadius: 15
                  }}>
                    {benefits.map((benefit) => {
                        return <Card elevation={0} style={{ maxWidth: 520, margin: 5, alignItems: "center", backgroundColor: "#E3EFFF", borderRadius: 15, padding: 25}}>
                          <div style={{ alignItems: "center", display: "flex", flexDirection: "row" }}>
                            <div style={{ display: "flex", flex: 2, alignItems: "center", alignContent: "center", justifyItems: "center", justifyContent: "center", maxWidth: 200, maxHeight: 200, }}>
                              <img src={benefit.img} style={{ width: "100%"}}></img>
                            </div>
                            <div style={{ maxWidth: 20 }}></div>
                            <div style={{flex: 5, display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start" }}>
                              <span style={{ color: "#4A6798", fontWeight: "bold", fontSize: 22, textAlign: "start" }}>{benefit.title}<br/></span>
                              <div style={{ height: 10 }}></div>
                              <span style={{ color: "#4A6798", fontSize: 16, fontWeight: "normal", textAlign: "start" }}>
                                {benefit.description}<br/>
                              </span>
                            </div>
                          </div>
                        </Card>
                  })}
                </div>
            <div style={{ height: 150 }}></div>
            <span style={{ textAlign: "center", color: "#253858", fontWeight: "normal", fontSize: 50 }}>Lighter static assets allow for faster load times and improved performance<br></br></span>
            <div style={{ height: 150 }}></div>
            <span style={{ textAlign: "center", color: "#253858", fontWeight: "bold", fontSize: 40 }}>Features<br></br></span>
            <div style={{ height: 30 }}></div>
            <div style={{
                  paddingTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: 'wrap',
                  width: "100%",
                  alignItems: "start",
                  justifyContent: "center"
                  }}>
                    {features.map((feature) => {
                        return <Card elevation={0} style={{ maxWidth: 150, minHeight:140 , margin: 10, alignItems: "center", backgroundColor: "#E3EFFF", borderRadius: 10, padding: 25}}>
                          <div style={{ padding: 3, display: "flex", alignItems: "center", alignContent: "center", justifyItems: "center", justifyContent: "center", maxWidth: 50, height: 50, margin:"auto" , backgroundColor: "#F5F9FF", borderRadius: 1000, boxShadow: "0 1px 5px 1px rgba(0,60,150,.3)" }}>
                            {feature.icon}
                          </div>
                          <div style={{marginTop: 20, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start" }}>
                            <span style={{ color: "#4A6798", fontWeight: "bold", fontSize: 16, textAlign: "center" }}>{feature.title}<br/></span>
                            <div style={{ height: 10 }}></div>
                            <span style={{ color: "#4A6798", fontSize: 13, fontWeight: "normal", textAlign: "center" }}>
                              {feature.description}<br/>
                            </span>
                          </div>
                        </Card>
                  })}
            </div>

            <div style={{ height: 150 }}></div>
            <span style={{ textAlign: "center", color: "#253858", fontWeight: "normal", fontSize: 40 }}>Start using Autocompress<br></br></span>
            <div style={{ height: 30 }}></div>
            <span style={{ color: "#4A6798", fontSize: 16, fontWeight: "normal", textAlign: "center" }}>
              Takes 6 clicks to set up. Always free for public repositories. All paid plans start with a free 14-day trial.</span>
            <div style={{ height: 50 }}></div>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyItems: "center", alignContent: "center", justifyContent: "center" }}>
              <Button appearance="primary" iconBefore={<BitbucketIcon appearance="inverse" label="" size="small" />} onClick={launchInstallationUrl}
              ><div style ={{ maxWidth: 200 }}>Install on Bitbucket</div></Button> 
              <div style={{ width: 10 }}></div>
              <Button appearance="default" onClick={() => navigate("/pricing")}
              ><div style ={{ maxWidth: 200 }}>Check pricing</div></Button>
            </div>

            <div style={{ height: 100 }}></div>
                
          </div>
          <BottomBar/>
          </div>
          

          
                  
        </Content>
    </PageLayout>
  );
}

export default observer(Radium(Landing));

export const Head = () => <title>Autocompress</title>
